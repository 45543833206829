<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">文件标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button @click="postdata(scope.row)" type="success" size="mini">批示</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		
		<!--审核弹出-->
		<el-dialog title="确认批示" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="批示意见" required>
							<el-input
							  type="textarea"
							  :rows="4"
							  placeholder="请输入批示意见"
							  v-model="sheng.wps">
							</el-input>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">通 过</el-button>
			</span>
		</el-dialog>
		
		
		
		<detail ref="mydetail"></detail>
		
	</div>
</template>

<script>
	import detail from '../components/detail.vue'
	import selectuser from '@/components/select_user.vue'
	export default {
		components:{ detail,selectuser },
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '文件标题',
						width: ''
					},
					{
						prop: 'lydw',
						name: '来文单位',
						width: '250'
					},
					{
						prop: 'sflb',
						name: '是否拟办',
						width: '110',
						type: 'sflb_tags'
					},
					{
						prop: 'jjcd',
						name: '紧急程度',
						width: '120',
						type:'jjcd'
					},
					{
						prop: 'addtime',
						name: '创建时间',
						width: ''
					}
				],
				tableData: [],
				showtc:false,
				sheng:{
					id:'',
					wps:''
				},
				user_name:'',
				user_id:''
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			queren(){
				if(!this.sheng.wps){
					this.$message.warning('请输入批示意见')
					return
				}
				this.$post({
					url: '/api/matter/wldpishi',
					params: this.sheng
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setsheng()
				})
			},
			setsheng(){
				this.sheng={
					id:'',
					wps:''
				}
				this.showtc = false
			},
			showdetail(row){
				this.$post({
					url: '/api/matter/details',
					params: {
						id:row.id
					}
				}).then((res) => {
					this.$refs.mydetail.info = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			postdata(row) {
				this.showtc = true
				this.sheng.id = row.id
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/matter/daiwps',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						type: 4
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'daildsp.scss';
</style>